<template>
	<div id="filter-component">
		<div class="m-2">
			<span class="fa fa-filter"></span>
			Filter
		</div>
		<hr>
		<!-- Predefined / Default Filters -->
		<div v-if="defaultFilters?.length && props.showDefaultFilters">
			<div class="row g-3 filter-group" v-for="(item, index) in defaultFilters" :key="index">
				<div class="col-auto">
					<Button @click="removeDefaultFilters(item)" severity="secondary" v-tooltip.left="'Remove Filter'">
						<span class="fa fa-times-circle" aria-hidden="true"></span>
					</Button>
				</div>

				<!-- field name  -->
				<div class="col-auto">
					<Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.field" :options="filterSchema"
						optionLabel="display_name" optionValue="field_name" placeholder="Filter by" class="w-100"
						id="filter-by" disabled />

				</div>

				<!-- operator -->
				<div class="col-auto">
					<Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.type" :options="defaultFilterOperator"
						optionLabel="label" optionValue="value" disabled placeholder="Filter type" class="w-100"
						id="filter-type" />
				</div>


				<!-- value -->
				<div class="col-auto">
					<InputText type="text" v-model="item.value" :value="ucwords(item.value)" disabled id="filter-value"
						class="w-100" />
				</div>
			</div>
		</div>

		<!-- User-Specified Filter Criteria -->
		<div class="row g-3 filter-group" v-for="(item, index) in filterInfo" :key="index">
			<div class="col-auto">
				<Button severity="secondary" @click="removeFilter(index)"
					v-tooltip.left="filters.length > 1 ? 'Remove Filter' : 'Clear Filter'">
					<span class="fa fa-times-circle" aria-hidden="true"></span>
				</Button>
			</div>

			<!-- field name  -->
			<div class="col-auto">
				<Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.filter.field" :options="fieldListItems"
					optionLabel="display_name" optionValue="field_name" placeholder="Filter by" class="w-100"
					@change="onChangeFilterBy(item.filter)" id="filter-by" />
			</div>

			<!-- operator -->
			<div class="col-auto">
				<Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.filter.type" :options="item.operators"
					optionLabel="label" optionValue="value" placeholder="Filter type" class="w-100"
					@change="onChangeFilterType(item.filter)" id="filter-type" />
			</div>

			<!-- value -->
			<div class="col-auto">

				<div v-if="(lookupDataLoading == item.fieldName)">
					<Skeleton height="2.2rem" width="200px"></Skeleton>
				</div>

				<!-- Possible Values / Lookup URL-->
				<template v-else-if="item.possibleValues.length > 0">
					<template v-if="multiSelectOperators.includes(item.filter.type)">
						<MultiSelect :scrollHeight="PV_SCROLL_HEIGHT" placeholder="-- Select --" display="chip" required
							v-model="item.filter.value" multiple optionLabel="text" optionValue="id"
							:showToggleAll="false" :selectAll="allSelected"
							@click="configureMultiSelect(item.filter, item.possibleValues)"
							:options="item.possibleValues" class="d-flex" id="filter-value" @change="onValueChange">
							<template #header>
								<div class="filter-p-multiselect-header">
									<Checkbox inputId="selectAll" v-model="allSelected" binary
										@change="multiSelectToggleSelectAll(item.filter, item.possibleValues, allSelected)" />
									<label for="selectAll" class="cursor-pointer ms-2">
										{{ allSelected ? 'Deselect All' : 'Select All' }}
									</label>
									<hr>
								</div>
							</template>

							<template #option="slotProps">
								<template v-if="item.filter.field === 'priority_score'">
									<img :src="getPriorityScoreGraphicUrl(slotProps.option.id)" style="width: 50px;"
										alt="Priority Score" v-tooltip.bottom="`Priority Score ${slotProps.option.id}`">
								</template>
							</template>

						</MultiSelect>
					</template>
					<template v-else>
						<Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.filter.value"
							:options="substituteNullIDsHack(item.possibleValues)" placeholder="-- Select --"
							class="w-100" optionLabel="text" optionValue="id" id="filter-value"
							@change="onValueChange" />
					</template>
				</template>

				<template v-else>
					<template v-if="item.schema.type === 'date'">
						<DatePicker v-model="item.filter.value" :disabled="item.disableValueElement" id="filter-value"
							class="w-100" date-format="m/d/yy" @update:model-value="onValueChange" />
					</template>

					<template v-else-if="item.schema.type === 'bool'">
						<Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.filter.value"
							:options="boolTrueFalseOption" :disabled="item.disableValueElement" optionLabel="label"
							optionValue="value" placeholder="-- Select --" class="w-100" id="filter-value"
							@change="onValueChange" />
					</template>

					<template v-else-if="item.schema.type === 'MONEY'">
						<InputNumber mode="currency" currency="USD" locale="en-US" fluid v-model="item.filter.value"
							:disabled="item.disableValueElement" @keyup.enter="submitFilter" inputId="filter-value"
							@value-change="onValueChange" :allow-empty="true" />
					</template>

					<template v-else>
						<InputText type="text" v-model="item.filter.value" :disabled="item.disableValueElement"
							@keyup.enter="submitFilter" id="filter-value" class="w-100" @change="onValueChange" />
					</template>
				</template>
			</div> <!-- end value options -->

			<div class="col-auto">
				<div :class="{ 'invisible': index + 1 !== filters.length }" class="d-inline-flex text-start">
					<Button severity="info" @click="addFilter" class="" title="Add Filter">
						<span class="fa fa-plus-circle" aria-hidden="true"></span>
					</Button>
				</div>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col text-center">
				<Button severity="info" @click="submitFilter" id="submit-filter">
					<span class="fa fa-sliders-h"></span> Apply
				</Button>
				<Button severity="secondary" @click="showConfirmClearAllFilterModal = true" class="ms-2"
					id="clear-filter">
					<span class="fa fa-times"></span> Clear All
				</Button>
			</div>
		</div>
	</div>

	<ModalDialog v-if="showConfirmClearAllFilterModal" title="Confirm Clear Filters"
		:close="() => showConfirmClearAllFilterModal = false">
		<Message severity="warn" :closable="false" class="my-2">
			Are you sure you want to clear the filters?
			<br>
			<br>
			This will remove all current filters and input, and cannot be undone.
		</Message>

		<template #footer>
			<Button @click="clearAllFilters" severity="warning">Confirm</Button>
			<Button severity="secondary" @click="showConfirmClearAllFilterModal = false">Close</Button>
		</template>
	</ModalDialog>

</template>

<style>
#filter-component #filter-by,
#filter-component #filter-type,
#filter-component #filter-value {
	width: 200px !important;
}

.filter-p-multiselect-header {
	padding: 12px;
}

.filter-p-multiselect-header hr {
	margin: 1rem 0 0 0;
}
</style>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Select from 'primevue/select'
import DatePicker from 'primevue/datepicker'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import MultiSelect from 'primevue/multiselect'
import Skeleton from 'primevue/skeleton'

import type {
	FilterSchema,
	FilterFields,
	Operator,
	OperatorSubstitution,
	OperatorExclusion,
	GenericKeyValueItem
} from "@/helpers/interface/general"
import dayjs from 'dayjs'
import { allFilterOperator, getApiErrorMessage, ucfirst, PV_SCROLL_HEIGHT, ucwords, getPriorityScoreGraphicUrl } from '@/helpers/common'
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import ModalDialog from '@/components/Shared/ModalDialog.vue'
import Message from 'primevue/message'

// https://github.com/trueroll/TrueVue/issues/1135
const SUB_NULL_PRIMEVUE_VALUE = "{{SubstituteNull}}"

const api = useAPI()
const props = defineProps<{
	activeFilters: FilterFields[],
	defaultFilters: FilterFields[] | undefined,
	filterSchema: FilterSchema[],
	operatorSubstitutions?: OperatorSubstitution[],
	operatorExclusions?: OperatorExclusion[],
	fieldExclusions?: string[],
	showDefaultFilters?: Boolean
}>()
const emit = defineEmits(["submitFilters", "removeFilters", "isDirty"])

const allSelected = ref(false)
const multiSelectToggleSelectAll = (model: any, items: { "id": string, "text": string }[], selectAll: boolean = true) => {
	model.value = !selectAll ? [] : items.map(item => item.id)
}
const configureMultiSelect = (model: any, items: string[]) => {
	allSelected.value = model.value.length === items.length
}
const defaultFilterType: Operator[] = ["ilike", "starts", "="]
const filterSchema = ref<FilterSchema[]>(props.filterSchema)
const filterInitialState = {
	field: "",
	type: "",
	value: ""
}
const defaultFilterOperator = computed(() => {
	return getAvailableOperators({
		field_name: "",
		type: "str",
		display_name: '',
		operators: null
	}, true)
})
const defaultFilters = ref<FilterFields[] | undefined>(props.defaultFilters)
const filters = ref<FilterFields[]>(props.activeFilters)

interface FilterInfo {
	fieldName: string;
	filter: FilterFields;
	schema: FilterSchema;
	disableValueElement: boolean;
	possibleValues: any[];
	operators: any[];
}

const filterInfo = computed(() => {
	return filters.value?.map(filter => {
		const schema = getSchema(filter) || { field_name: filter.field, type: "str" } as FilterSchema
		const output: FilterInfo = {
			fieldName: filter.field,
			filter: filter,
			schema: schema,
			disableValueElement: noValueOperators.includes(filter.type),
			possibleValues: getPossibleValues(schema),
			operators: getAvailableOperators(schema)
		}

		return output
	})
})

const fieldListItems = computed(() => {
	const exclusions = props.fieldExclusions || []
	return filterSchema.value.filter(x => !exclusions.includes(x.field_name))
})

const boolTrueFalseOption = [
	{
		value: "true",
		label: "Yes"
	},
	{
		value: "false",
		label: "No"
	}
]

const noValueOperators = ["is null", "is not null"]
const multiSelectOperators = ["in", "not in", "has all"]
const lookupDataLoading = ref<string | null>(null)

interface EmitFilter {
	filters: FilterFields[];
	removedDefaultFilter: FilterFields | null;
	removeAll: Boolean;
}

let emitFilter: EmitFilter = {
	filters: filters.value,
	removedDefaultFilter: null,
	removeAll: false
}

const updateEmitFilter = (newFilters: FilterFields[] | undefined, removedDefaultFilter: FilterFields | null = null, removeAll: boolean = false) => {
	return {
		filters: newFilters,
		removedDefaultFilter: removedDefaultFilter,
		removeAll: removeAll
	} as EmitFilter
}

const getSchema = (filter: FilterFields) => {
	return filterSchema.value.find(f => f.field_name === filter.field)
}

const onChangeFilterBy = async (filter: FilterFields) => {
	emit("isDirty", true)
	const schema = getSchema(filter)
	filter.type = schema?.default_operator || ""
	filter.value = ""
	if (schema && schema.lookup_url) {
		await fetchLookupData(schema)
	}
}

const onChangeFilterType = (filter: FilterFields) => {
	if (![
		...defaultFilterType,
		">", ">=", "<", "<=", "!=", "is null", "is not null"
	].includes(filter.type)) {
		filter.value = ""
		emit("isDirty", true)
	}
}

const onValueChange = () => {
	emit("isDirty", true)

	// If the user changes the value of a filter
	// then uncheck select all button
	allSelected.value = false
}

const removeDefaultFilters = (removedDefaultFilter: FilterFields) => {
	defaultFilters.value = defaultFilters.value?.filter(filter => filter !== removedDefaultFilter)
	filters.value = filters.value?.filter(filter => filter !== removedDefaultFilter)
	emitFilter = updateEmitFilter(filters.value, removedDefaultFilter)
	emit('removeFilters', emitFilter)
}

const removeFilter = (index: number) => {
	if (filters.value.length <= 1) {
		filters.value = [{ ...filterInitialState }]
		submitFilter()
	} else {
		filters.value.splice(index, 1)
		emit("isDirty", true)
	}
	if (filters.value.length + 1 <= 1) {
		const emitFilter = updateEmitFilter(filters.value)
		emit('removeFilters', emitFilter)
	}
}

const getPossibleValues = (field: FilterSchema): GenericKeyValueItem[] => {
	return field && field.possible_values ? field.possible_values.map(value => {
		if (typeof value === "string") {
			return {
				text: ucfirst(value),
				id: value,
			}
		} else if (typeof value === "number") {
			return {
				text: value.toString(),
				id: value.toString()
			}
		} else if (typeof value === "object") {
			return {
				text: value.text,
				id: value.id
			}
		} else {
			return { text: value, id: value }
		}
	}) : []
}

const fetchLookupData = async (schema: FilterSchema) => {
	if (!schema || !schema.lookup_url) {
		return
	}

	// if we've already loaded data for this field, then exit
	if (schema.possible_values && schema.possible_values.length > 0) {
		return
	}

	lookupDataLoading.value = schema.field_name

	try {
		const response = await api.get(schema.lookup_url)
		let data = response.data

		if (data && data.length > 0 && typeof data[0] === "string") {
			data = data.map((x: string) => { return { "id": x, "text": x } })
		}
		schema.possible_values = data
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
	}
	lookupDataLoading.value = null
}


const getFieldType = (fieldName: string): string => {
	const field = filterSchema.value.find(f => f.field_name === fieldName)
	if (field?.lookup_url) {
		return field.type
	}
	else return transformFieldType(field ? field.type : 'str')
}

const transformFieldType = (type: string): string => {
	switch (type) {
		case "str":
			return "text"
		case "date":
			return "date"
		default:
			return type
	}
}
const getAvailableOperators = (schema: FilterSchema, showAll: boolean = false): any => {
	let ops = schema?.operators || (!showAll ? defaultFilterType : allFilterOperator)

	const substitutions = props.operatorSubstitutions || []
	const exclusions = props.operatorExclusions || []

	if (exclusions.length) {
		ops = ops.filter((operator: Operator) => {
			const possible_exc = exclusions.filter(exc => exc.operator === operator)
			const match = possible_exc.find(exc => {
				return (
					(exc.field === schema.field_name && !exc.type)
					|| (exc.type === schema.type && !exc.field)
					|| (exc.field === schema.field_name && exc.type === schema.type)
				)
			})
			return !match
		})
	}

	return ops.map((operator: Operator) => {
		const possible_subs = substitutions.filter(sub => sub.operator === operator)
		const match = possible_subs.find(sub => {
			return (
				(sub.field === schema.field_name && !sub.type)
				|| (sub.type === schema.type && !sub.field)
				|| (sub.field === schema.field_name && sub.type === schema.type)
				|| (!sub.field && !sub.type)
			)
		})
		return {
			value: operator,
			label: match ? match.text : operatorMapping[operator]
		}
	})
}

const operatorMapping: { [key in Operator]: string } = {
	"=": "Is Exactly",
	"!=": "Is Not Exactly",
	">": "Is Greater Than",
	">=": "Is Greater Than Or Equal To",
	"<": "Is Less Than",
	"<=": "Is Less Than Or Equal To",
	"is null": "Is Empty",
	"is not null": "Is Not Empty",
	"in": "Includes Any",
	"not in": "Does Not Include",
	"like": "Contains",
	"not like": "Does Not Contain",
	"ilike": "Contains",
	"not ilike": "Does Not Contain",
	"starts": "Starts With",
	"ends": "Ends With",
	"~*": "Matches",
	"has all": "Includes All",
}


const addFilter = () => {
	filters.value.push({ ...filterInitialState })
	emit("isDirty", true)
}


// https://github.com/trueroll/TrueVue/issues/1135
const substituteNullIDsHack = (items: GenericKeyValueItem[] | string[]) => {

	const l = items.length;
	if (!l || typeof items[0] === "string") {
		return;
	}
	const kvItems = [...items] as GenericKeyValueItem[]
	for (let i = 0; i < l; i++) {
		if (kvItems[i].id === null) {
			kvItems[i].id = SUB_NULL_PRIMEVUE_VALUE
		}
	}
	return kvItems
}

const showConfirmClearAllFilterModal = ref(false)
const clearAllFilters = () => {
	// Clear all filters, including default filters, only if they are displayed in the filter section
	const updatedFilters = updateEmitFilter([], null, !!props.showDefaultFilters)
	defaultFilters.value = []
	clearFilters()
	emit('removeFilters', updatedFilters)
}

const cleanFilter = (filteredArray: FilterFields[]) => {
	let isDirty = false

	return {
		filter: filteredArray.filter(obj => {

			// Format Date
			if (getFieldType(obj.field) === 'date') {
				if (dayjs(obj.value).isValid()) {
					obj.value = dayjs(obj.value).format('YYYY-MM-DD')
				} else {
					obj.value = ""
					isDirty = true
					return false
				}
			}

			if (["=", "!="].includes(obj.type)) {
				if (obj.value === null) {
					obj.type = obj.type === "=" ? "is null" : "is not null"
					obj.value = obj.type
				}
			}

			// Removing from payload body
			if (obj.type === "in") {
				if (obj.value === "" || obj.value?.length === 0) {
					isDirty = true
					return false
				}
			}

			// https://github.com/trueroll/TrueVue/issues/1135
			if (obj.value === SUB_NULL_PRIMEVUE_VALUE) {
				obj.type = "is null"
				obj.value = "is null"
			}

			if (!obj.field || !obj.type) {
				isDirty = true
				return false
			}

			return true
		})
		,
		isDirty
	}
}

const submitFilter = () => {
	const currentFilters = ref<FilterFields[]>([])
	currentFilters.value = JSON.parse(JSON.stringify(filters.value))
	const validatedFilters = cleanFilter(currentFilters.value)
	const hasEmptyFirstFilter = () => {
		if (currentFilters.value?.[0]) {
			if (
				currentFilters.value[0].field === "" &&
				currentFilters.value[0].type === "" &&
				currentFilters.value[0].value === ""
			) {
				return true
			}
		}
		return false
	}
	if (validatedFilters.isDirty && !hasEmptyFirstFilter()) {
		toast.warning("Some filter fields are incomplete. Please fill in all required values before applying the filter.", { "position": "top" })
		return
	}
	emit('submitFilters', validatedFilters.filter)
	emit("isDirty", false)
}



const clearFilters = () => {
	filters.value = [{ ...filterInitialState }]
}

onMounted(() => {
	if (!filters.value.length) {
		filters.value = [filterInitialState]
	}
})

defineExpose({
	clearFilters
})
</script>

<style scoped>
.filter-group {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
}
</style>