<template>
    <div ref="candidateDataView" id="candidateDataView">
        <div class="row action-toolbar-container" id="taxroll-list-table-header-section">
            <div class="col-lg-8 col-md-12" v-if="!loading">
                <Button :disabled="totalRecords <= 0 || isShowAllSelectedItems" v-if="!toggledSelectAll" id="select-all"
                    type="button" outlined class="me-2" @click="onSelectAllChange()" severity="secondary">
                    <span class="fa fa-check-square" aria-hidden="true"></span>
                    Select All
                </Button>
                <Button v-else :disabled="isShowAllSelectedItems" id="deselect-all" type="button" outlined class="me-2"
                    @click="clearSelection()" severity="secondary">
                    <span class="fa fa-square-minus" aria-hidden="true"></span>
                    Clear Selection
                </Button>
                <Button type="button" class="me-2" id="queue-action-item" @click="onBulkActionClick('UPDATE_QUEUE')"
                    severity="success" :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)">
                    <span class="fa fa-tasks" aria-hidden="true"></span>
                    Update Queue
                </Button>
                <SplitButton label="Assign" rounded class="me-2" :model="assignActionItems" id="assign-action-item"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)"
                    @click="onBulkActionClick('ASSIGN')">
                    <span class="fa fa-user-plus" aria-hidden="true"></span>
                    Assign
                </SplitButton>
                <Button type="button" class="me-2" id="label-action-item" @click="onBulkLabelClick" severity="info"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)">
                    <span class="fa fa-tags" aria-hidden="true"></span>
                    Labels
                </Button>
                <Button type="button" class="me-2" id="note-action-item" @click="onBulkNoteClick" severity="info"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)">
                    <span class="fa fa-comment" aria-hidden="true"></span> Note
                </Button>
                <span v-if="props.queue === 'unreleased_ir'">
                    <Button type="button" class="me-2" id="delete-ir-action-item" @click="onBulkIRClick('delete')"
                        severity="info" :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)">
                        <span class="fa fa-file-excel" aria-hidden="true"></span> Delete
                    </Button>
                </span>
            </div>
            <div class="col-lg-8 col-md-12 d-inline-flex" v-else>
                <Skeleton width="115px" height="34px" class="ms-2 mb-2"></Skeleton>
                <Skeleton width="145px" height="34px" class="ms-2 mb-2"></Skeleton>
                <Skeleton width="95px" height="34px" class="ms-2 mb-2"></Skeleton>
            </div>
            <div class="col-lg-4 col-md-12">
                <StrapCardHeaderActions v-model:quickSearchQuery="quickSearchQuery" v-model:filters="filters"
                    :quickSearchQueryTitle="quickSearchQueryTitle" :sortTitles="sortTitles" :filterConfig="filterConfig"
                    :loading="loading" :sortOrder="sortOrder" :parentDataView="candidateDataView"
                    :isShowAllSelectedItems="isShowAllSelectedItems" @changeSortOrder="changeSortOrder"
                    @submitFilters="handleSubmitFilters" @removeFilters="handleRemoveFilters"
                    @onFiltersDirtyChanged="onFiltersDirtyChanged" ref="strapCardHeaderActionsRef" />
            </div>
        </div>

        <div v-show="selectedItems.length > 0 && (!isRecordsInCurrentPageSelected && !isAllRecordsMatchingFiltersSelected) && !loading"
            class="text-center list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false">
                You have selected
                <a v-tooltip.bottom="`View selected ${recordLabel}`" href="javascript:;" @click="showAllSelectedItems()"
                    id="view-all-selected">
                    {{ selectedItems.length }} {{ recordLabel }}.
                </a>
                <a href="javascript:;" @click="clearAllSelectedItems()">Clear Selection </a>
            </Message>
        </div>

        <div v-show="(isRecordsInCurrentPageSelected || isAllRecordsMatchingFiltersSelected) && !loading"
            class="text-center list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false">

                <span v-if="isRecordsInCurrentPageSelected && !isAllRecordsMatchingFiltersSelected">
                    {{ formatNumberWithCommas(selectedItems.length) }} {{ recordLabel }} on the current page are
                    selected.
                    <a v-if="!isAllRecordsMatchingFiltersSelected && (selectedItems.length < totalRecords)"
                        href="javascript:;" @click="toggleSelectAllMatchingFilters()">
                        Select all {{ formatNumberWithCommas(totalRecords) }} {{ recordLabel }} matching current
                        filters
                    </a>
                </span>

                <span v-if="isAllRecordsMatchingFiltersSelected">
                    All {{ formatNumberWithCommas(totalSelectedCandidate) }} {{ recordLabel }} selected, deselection
                    by
                    individual record disabled
                    <label
                        v-tooltip.bottom="'Deselection is only possible on a per-page basis. At the bottom of the list, you can increase the page size from the default of 25 up to 500 if necessary.'">
                        <span class="fa fa-circle-info"></span>
                    </label>
                    <a v-if="isAllRecordsMatchingFiltersSelected" href="javascript:;" @click="clearAllSelectedItems()">
                        Clear Selection
                    </a>
                </span>
            </Message>
        </div>

        <div v-show="showQSMessage" class="text-center list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false"
                v-if="(totalRecords === 0 && !enabledQuickSearchAllQueue) && props.queue !== QueueMap.All.id">
                Would you like to search through
                <a href="javascript:;" @click="() => quickSearchAllQueue()">
                    all queues
                </a>
                instead?
            </Message>

            <Message class="mb-2" severity="secondary" :closable="false"
                v-if="(totalRecords === 0) && props.queue === QueueMap.All.id && !qsIncludeArchive">
                Would you like to
                <a href="javascript:;" @click="() => quickSearchAllQueue(true)">
                    include archived {{ recordLabel }}
                </a>
                in your search?
            </Message>
        </div>

        <div v-if="loading">
            <div class="dataview-list-row-card"></div>
            <div v-for="i in 5" :key="i" class="row candidate-list-row-card dataview-list-row-card">
                <div class="col align-content-center">
                    <div class="p-2">
                        <Skeleton width="30px" height="2rem"></Skeleton>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 align-content-center">
                    <div class="p-2">
                        <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="70%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="40%" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 align-content-center">
                    <div class="p-2">
                        <Skeleton width="50%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 align-content-center">
                    <div class="p-2">
                        <Skeleton width="50%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 align-content-center">
                    <div class="p-2">
                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                        <div class="d-flex mb-2">
                            <Skeleton width="50%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="80%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="30%" height="1rem" class="me-2"></Skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1 col-md-6 align-content-center">
                    <div class="p-2 d-flex">
                        <Skeleton width="100%" height="4rem" class="me-2"></Skeleton>
                    </div>
                </div>
            </div>
        </div>

        <BasicStrapCards :loading="loading" :data="data" :firstIndex="first" :rows="rows" :componentID="props.queue"
            :totalRecords="totalRecords" @onpage="(event: any) => onPage(event)" :parentDataView="candidateDataView"
            :key="paginationResetCounter">
            <template #list="slotProps">
                <div v-for="(item, index) in (slotProps.data as CandidateList[])" :key="index"
                    class="row dataview-list-row-card" :id="`row_${index}`"
                    :class="{ 'attention-needed fw-bold': item.queue === 'snooze' && isSnoozeExpired(item.snooze_until), ['strap-' + item.tru_id]: true }">
                    <div class="col-1 align-content-center" id="selectionColumn">
                        <div class="p-3">
                            <Checkbox v-model="selectedItems" :inputId="item.tru_id" name="category"
                                :value="item.tru_id" />
                        </div>
                    </div>
                    <!-- Qualification Container -->
                    <div class="col-lg-3 col-md-12 align-content-center clipboard-copy-wrapper">
                        <div class="p-2">
                            <div class="mb-2">
                                <span>
                                    <a :href="`/candidate-details/${item.tru_id}`" target="_blank">
                                        {{ item.parcel_num }}
                                    </a>
                                </span>
                                <a href="javascript:;" v-tooltip.bottom="'Add to Quick Search'"
                                    class="quick-search-link mx-2" @click="addToQuickSearch(item.parcel_num)">
                                    <span class="fa fa-search fa-sm"></span>
                                </a>

                                <a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'"
                                    class="copy-to-clipboard me-2" @click="copyTextToClipboard(item.parcel_num)">
                                    <span class="fa-regular fa-copy fa-sm"></span>
                                </a>

                                <!-- Show 'Add to To-Do' or 'Undo Add to To-Do' icon based on its state -->
                                <a href="javascript:;"
                                    v-tooltip.bottom="visibleUndoButtons[item.tru_id] ? 'Undo Add to To-Do' : 'Add to To-Do list'"
                                    v-if="item.queue !== QueueMap.Todo.id"
                                    :class="visibleUndoButtons[item.tru_id] ? 'undo-to-todo-list' : 'add-to-todo-list'"
                                    @click="visibleUndoButtons[item.tru_id] ? undoAddToTodoList(item.tru_id) : addToTodoList(item.tru_id)"
                                    :id="!visibleUndoButtons[item.tru_id] ? 'add-todo-' + item.tru_id : ''">
                                    <span
                                        :class="visibleUndoButtons[item.tru_id] ? 'fas fa-undo fa-sm' : 'fas fa-thumbtack fa-sm'"></span>
                                </a>

                                <!-- Show 'Added to To-Do' icon only if the item is in the To-Do queue and the 'All' tab is active -->
                                <span class="fas fa-thumbtack fa-sm added-to-todo-list" v-tooltip.bottom="'Pinned'"
                                    v-if="item.queue === QueueMap.Todo.id && [QueueMap.All.id, QueueMap.Todo.id].includes(props.queue)">
                                </span>

                                <Chip label="Archived" class="ms-2 mb-2 archived-chip chip-light chip-xs"
                                    v-if="(props.queue === QueueMap.All.id || props.queue === QueueMap.AssignedToMe.id) && item.queue === QueueMap.Archive.id"
                                    icon="fa fa-archive">
                                </Chip>

                                <a v-if="item?.may_have_been_sold" class="float-end"
                                    :href="`/candidate-details/${item.tru_id}`" target="_blank"
                                    v-tooltip.bottom="'This property may have been sold'">
                                    <div class="icon-container">
                                        <i class="fa-solid fa-tag tag-icon"></i>
                                        <span class="icon-text">SOLD</span>
                                    </div>
                                </a>
                            </div>
                            <div class="mb-2" v-if="item.secondary_id">
                                Alt ID {{ item.secondary_id }}
                            </div>
                            <div class="mb-2">
                                {{ item.situs }}
                            </div>
                            <div v-if="item?.owners?.length">
                                <div class="mb-2" v-for="(owner, index) in item.owners" :key="index">
                                    {{ owner }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Benefit Container -->
                    <div class="col-lg-3 col-md-4 align-content-center">
                        <div class="p-2">
                            <div v-if="item?.exemptions?.length">
                                <div class="mt-2" v-for="(exemption, index) in item.exemptions" :key="index">
                                    {{ exemption }}
                                </div>
                            </div>
                            <div v-if="item.effective_date" class="mt-2">
                                Effective {{ formatDate(item.effective_date) }}
                            </div>
                        </div>
                    </div>
                    <!-- Metadata Container -->
                    <div class="col-lg-2 col-md-4 align-content-center">
                        <div class="p-2">
                            <div v-if="item.last_queue_update" class="mb-2">
                                <span v-tooltip="formatDate(item.last_queue_update)">
                                    {{ lastQueueChange(item.last_queue_update, item.queue) }}
                                </span>
                            </div>
                            <div class="mb-2" v-if="props.queue === 'inbox'">
                                <div v-if="updatedOn(item)">
                                    <span v-tooltip="formatDate(updatedOn(item))">
                                        <template v-if="timeDiffForHumans(updatedOn(item), 'day')">
                                            Updated {{ timeDiffForHumans(updatedOn(item), 'day') }} days ago
                                        </template>
                                        <template v-else>
                                            Updated {{ dayjs(updatedOn(item)).fromNow() }}
                                        </template>
                                    </span>
                                </div>
                            </div>
                            <div v-if="!['investigate_now', 'released_ir'].includes(props.queue)">
                                <div class="mb-2 text-capitalize">Moved from: {{ getQueueTextByID(item.prev_queue) }}
                                </div>
                                <div class="mb-2" v-if="item.last_queue_update_user">By: {{
                                    item.last_queue_update_user }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- Workflow Container -->
                    <div class="col-lg-2 col-md-4 align-content-center">
                        <div class="p-2">
                            <span v-if="item.assigned_user_id">
                                <Chip :label="item.assigned_user"
                                    class="me-2 mb-2 tr-chip assigned-to-chip cursor-pointer"
                                    @click="displayUserInfo($event, item.assigned_user_id)" />
                            </span>
                            <span v-else class="mb-2">
                                <i>Unassigned</i>
                            </span>
                            <div v-if="item.labels" class="d-block mt-2">
                                <span v-for="(label, index) in item.labels" :key="index">
                                    <Chip :label="label" class="me-2 mb-2 tr-chip label-chip" />
                                </span>
                            </div>
                        </div>

                    </div>
                    <!-- Decision Support Container -->
                    <div class="col-lg-1 col-md-6 align-content-center">
                        <div class="p-2">
                            <div v-if="promonListLayoutQueues.includes(props.queue)">
                                <div v-if="promonListLayout === 'flags'">
                                    <span v-for="flag in item.flags" :key="flag" class="d-block" style="margin: 3px">
                                        {{ flag }}
                                    </span>
                                </div>
                                <div v-else class="candidate-list-img-container">
                                    <div v-if="item.has_ir">
                                        <img :src="RELEASED_IR_GRAPHIC_URL" alt="Priority Score">
                                    </div>
                                    <div v-else>
                                        <img :src="getPriorityScoreGraphicUrl(item.priority_score)"
                                            alt="Priority Score">
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="props.queue === 'questionnaire'">{{ item.mailing_template }}</div>
                            <div v-else-if="props.queue === 'unqualified'">
                                <div class="mb-2">
                                    {{ formatter.format(item.recovered_amount as unknown as number) }}
                                </div>
                                <div>
                                    <StrapCardListDetailsButton :id="item.tru_id"
                                        :click-handler="handleActivateListDrawerClick" />
                                </div>
                            </div>
                            <div v-else-if="props.queue === 'snooze'">
                                <div class="mb-2">
                                    Snoozed
                                    <span v-if="isSnoozeExpired(item.snooze_until)">Expired</span>
                                    <span v-else>Until</span>
                                    {{ formatDate(item.snooze_until) }}
                                </div>
                                <div>
                                    <StrapCardListDetailsButton :id="item.tru_id"
                                        :click-handler="handleActivateListDrawerClick" />
                                </div>
                            </div>
                            <div v-else-if="props.queue === 'archive'">
                                <div class="mb-2 text-capitalize">{{ item.final_outcome }}</div>
                            </div>
                            <div v-else-if="props.queue === 'investigate_now'">
                                <StrapCardListDetailsButton :id="item.tru_id"
                                    :click-handler="handleActivateListDrawerClick" />
                            </div>
                            <div v-else-if="['unreleased_ir', 'released_ir'].includes(props.queue)">
                                <div v-if="item.has_ir" class="mt-2">
                                    <router-link target="_blank"
                                        :to="{ name: 'investigationReport', params: { tru_id: item.tru_id, ir_id: item.ir_id } }">
                                        <img :src="RELEASED_IR_GRAPHIC_URL" alt="Priority Score">
                                        Investigation Report
                                    </router-link>
                                </div>
                                <div v-else>
                                    <span v-for="flag in item.flags" :key="flag" class="d-block" style="margin: 3px">
                                        {{ flag }}
                                    </span>
                                </div>
                            </div>
                            <div v-else>
                                <div class="mb-2 text-capitalize">{{ getQueueTextByID(item.queue) }}</div>
                                <div>
                                    <StrapCardListDetailsButton :id="item.tru_id"
                                        :click-handler="handleActivateListDrawerClick" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BasicStrapCards>

        <div id="promon-modals">

            <AssignUserDialog :loadFailureHandler="loadFailureHandler"
                :getFilterList="getFilterList" :isRecordsInCurrentPageSelected="isRecordsInCurrentPageSelected"
                v-model:isAllRecordsMatchingFiltersSelected="isAllRecordsMatchingFiltersSelected"
                v-model:action="selectedBulkAction" v-model:totalSelectedRecord="totalSelectedCandidate"
                v-model:selectedItems="selectedItems" v-model:userList="userList" @save="saveBulkAssign"
                @close="closeAssignDialog" parentURL="taxroll"/>

            <UpdateLabelDialog :config="'candidates'" :loadFailureHandler="loadFailureHandler"
                :getFilterList="getFilterList" :isRecordsInCurrentPageSelected="isRecordsInCurrentPageSelected"
                v-model:isAllRecordsMatchingFiltersSelected="isAllRecordsMatchingFiltersSelected"
                v-model:selectedItems="selectedItems" v-model:labelList="labelList"
                v-model:totalSelectedRecord="totalSelectedCandidate" v-model:showModal="showLabelDialog"
                @save="saveBulkLabel" @close="closeLabelDialog" parentURL="taxroll"/>

            <BulkActionModalDialog :visible="bulkNote.showNoteDialog?.value"
                :dialogTitle="`Add note to ${formatNumberWithCommas(totalSelectedCandidate)} ${recordLabel}`"
                :closeDialog="closeNoteDialog" :selectAll="isAllRecordsMatchingFiltersSelected"
                :isRecordsInCurrentPageSelected="isRecordsInCurrentPageSelected">
                <template #form-fields>
                    <Textarea v-model="bulkNote.note.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
                </template>
                <template #footer-button>
                    <Button @click="submitBulkNote" id="bulk-note" :disabled="bulkNote.loading.value">
                        <span class="fa fa-edit" aria-hidden="true" v-if="!bulkNote.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="bulkNote.loading.value"></span>
                        Submit
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkIR.showDeleteIRDialog?.value"
                :dialogTitle="`Delete ${formatNumberWithCommas(totalSelectedCandidate)} report(s)`"
                :closeDialog="closeIRDialog" :selectAll="isAllRecordsMatchingFiltersSelected"
                :isRecordsInCurrentPageSelected="isRecordsInCurrentPageSelected">
                <template #form-fields>
                    <Message severity="warn" :closable="false" class="my-2">
                        Are you sure you want to delete the {{ formatNumberWithCommas(totalSelectedCandidate) }}
                        selected
                        investigation report(s) ? This action cannot be undone.
                    </Message>
                </template>
                <template #footer-button>
                    <Button @click="submitBulkIR('delete')" :disabled="bulkIR.loading.value" id="delete-ir-action-item">
                        <span class="fa fa-file-excel" aria-hidden="true" v-if="!bulkIR.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="bulkIR.loading.value"></span>
                        Delete
                    </Button>
                </template>
            </BulkActionModalDialog>

            <BulkActionModalDialog :visible="bulkQueue.showQueueDialog?.value" :dialogTitle="bulkQueue.modalTitle.value"
                :closeDialog="closeQueueDialog" :selectAll="isAllRecordsMatchingFiltersSelected"
                :isRecordsInCurrentPageSelected="isRecordsInCurrentPageSelected">
                <template #form-fields>

                    <div class="element-container">
                        <label for="queue" class="form-label form-label-required">Move to</label>
                        <Select :scrollHeight="PV_SCROLL_HEIGHT" id="queue" v-model="selectedQueue"
                            :options="filteredQueueOptions" optionLabel="label" optionValue="value"
                            placeholder="-- Select --" class="d-flex" />
                    </div>

                    <CandidateUpdateQueueGeneric v-if="useGenericOptionsForQueueChange(bulkQueue.queue.value)"
                        v-model:optionalNote="bulkQueue.optionalNote.value" />

                    <CandidateUpdateQueueQuestionnaire v-if="bulkQueue.queue.value === 'questionnaire'"
                        v-model:mailingTemplate="bulkQueue.mailingTemplate.value"
                        v-model:optionalNote="bulkQueue.optionalNote.value"
                        :mailingTemplateDropdown="mailingTemplateDropdown" />

                    <CandidateUpdateQueueMonitor v-if="bulkQueue.queue.value === 'monitor'"
                        v-model:reasons="bulkQueue.reason.value" v-model:optionalNote="bulkQueue.optionalNote.value"
                        :reasonsDropdown="reasonsDropdown" />

                    <CandidateUpdateQueueSnooze v-if="bulkQueue.queue.value === 'snooze'"
                        v-model:reasons="bulkQueue.reason.value" v-model:optionalNote="bulkQueue.optionalNote.value"
                        v-model:snoozeUntil="bulkQueue.snoozeUntil.value" :reasonsDropdown="reasonsDropdown"
                        :snoozeDays="snoozeDays" />

                    <CandidateUpdateQueueUnqualified v-if="bulkQueue.queue.value === 'unqualified'"
                        v-model:reasons="bulkQueue.reason.value"
                        v-model:unqualifiedStartYear="bulkQueue.unqualifiedStartYear.value"
                        v-model:mailingTemplate="bulkQueue.mailingTemplate.value"
                        v-model:lienOrBackTaxes="bulkQueue.lienOrBackTaxes.value"
                        v-model:backTaxLienAmount="bulkQueue.backTaxLienAmount.value"
                        v-model:optionalNote="bulkQueue.optionalNote.value"
                        v-model:recoveredAmount="bulkQueue.recoveredAmount.value"
                        :totalCandidateSelected="totalSelectedCandidate"
                        :mailingTemplateDropdown="mailingTemplateDropdown" :reasonsDropdown="reasonsDropdown" />

                    <CandidateUpdateQueueArchive v-if="bulkQueue.queue.value === 'archive'"
                        v-model:saleDate="bulkQueue.saleDate.value" v-model:optionalNote="bulkQueue.optionalNote.value"
                        :totalCandidateSelected="totalSelectedCandidate" />

                </template>
                <template #footer-button>
                    <Button @click="toggleUpdateQueueAction('confirm')" id="add-bulk-queue"
                        :disabled="bulkQueue.loading.value || bulkQueueUpdateButtonDisabled">
                        <span class="fa fa-plus-circle" aria-hidden="true" v-if="!bulkQueue.loading.value"></span>
                        <span class="fa fa-spinner fa-spin" aria-hidden="true" v-else></span>
                        Update
                    </Button>
                </template>
            </BulkActionModalDialog>
        </div>
    </div>

    <Drawer v-model:visible="listDrawerVisible" position="right" header="Details">
        <CandidateListDrawerContent :tru_id="listDrawerItemID" />
    </Drawer>

    <ModalDialog v-if="showArchiveWarningModal" title="Queue Update Confirmation"
        :close="() => showArchiveWarningModal = false">
        <Message severity="warn" :closable="false" class="my-2">
            Please note that {{ countSelectedArchiveItems }} of the selected {{ recordLabel }} are archived.
            <br>
            <br>
            Are you sure you want to proceed with this change?
        </Message>

        <template #footer>
            <Button @click="toggleUpdateQueueAction('submit')" severity="warning"
                :disabled="bulkQueue.loading.value || bulkQueueUpdateButtonDisabled">
                <span class="fa fa-plus-circle" aria-hidden="true" v-if="!bulkQueue.loading.value"></span>
                <span class="fa fa-spinner fa-spin" aria-hidden="true" v-else></span>
                Confirm
            </Button>
            <Button severity="secondary" @click="toggleUpdateQueueAction('close')">Close</Button>
        </template>
    </ModalDialog>

    <Popover ref="op">
        <UserInfo :userId="selectedUserInfo" />
    </Popover>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch, onUnmounted, nextTick } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue';
import { useAPI } from "@/helpers/services/api"
import { useProfile } from "@/stores/profile"
import { usePendoStore } from '@/stores/pendo';
import { toast } from "@/helpers/toast"
import {
	RELEASED_IR_GRAPHIC_URL,
	getApiErrorMessage,
	formatDate,
	type QueueID,
	type QueueText,
	timeDiffForHumans,
	ucfirst,
	validateUserPermission,
	formatNumberWithCommas,
	MAX_NOTE_LENGTH,
	getCurrencyFormatter,
	QueueMap,
	ucwords,
	getPriorityScoreGraphicUrl,
	PV_SCROLL_HEIGHT,
	promonQueueTabs,
	DEFAULT_HOMEPAGE,
	isSnoozeExpired,
	InternalSettings,
	copyTextToClipboard,
	scrollToView,
	toggleCheckboxes,
	getQueueTextByID
} from "@/helpers/common"
import type {
	DataTableEvent,
	FilterConfig,
	FilterFields,
	FilterSchema,
} from "@/helpers/interface/general"
import { FilterMatchMode } from '@primevue/core/api'
import Chip from 'primevue/chip'
import Skeleton from 'primevue/skeleton';
import type { OperatorExclusion, OperatorSubstitution } from "@/helpers/interface/general"
import type { CandidateList, Label } from "@/helpers/interface/candidates";
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue"
import Select from 'primevue/select'
import Textarea from 'primevue/textarea';
import Message from 'primevue/message'
import StrapCardHeaderActions from "@/components/Shared/StrapCardHeaderActions.vue"
import Checkbox from 'primevue/checkbox';
import SplitButton from 'primevue/splitbutton'
import Button from 'primevue/button'
import Drawer from 'primevue/drawer'
import CandidateListDrawerContent from "@/components/Candidates/CandidateListDrawerContent.vue"
import StrapCardListDetailsButton from "@/components/Shared/StrapCardListDetailsButton.vue"
import { useAdminDetails } from "@/stores/adminDetails";
import {
	performIRAction,
	performNoteAction,
	performQueueAction,
	type BulkIRAction,
} from "@/helpers/bulkActions";
import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import BulkActionModalDialog from '@/components/Shared/BulkActionModalDialog.vue'
import CandidateUpdateQueueQuestionnaire from '@/components/Candidates/CandidateUpdateQueueQuestionnaire.vue'
import CandidateUpdateQueueMonitor from '@/components/Candidates/CandidateUpdateQueueMonitor.vue'
import CandidateUpdateQueueSnooze from '@/components/Candidates/CandidateUpdateQueueSnooze.vue'
import CandidateUpdateQueueUnqualified from '@/components/Candidates/CandidateUpdateQueueUnqualified.vue'
import CandidateUpdateQueueArchive from '@/components/Candidates/CandidateUpdateQueueArchive.vue'
import CandidateUpdateQueueGeneric from '@/components/Candidates/CandidateUpdateQueueGeneric.vue'
import { useCandidateDetailsStore } from '@/stores/candidateDetails'
import { useTaxroll } from '@/stores/taxroll'
import ModalDialog from '@/components/Shared/ModalDialog.vue'
import Popover from "primevue/popover"
import UserInfo from '@/components/Shared/UserInfo.vue'
import AssignUserDialog from '@/components/Shared/AssignUserDialog.vue'
import UpdateLabelDialog from '@/components/Shared/UpdateLabelDialog.vue'

dayjs.extend(relativeTime)

const props = defineProps({
	queue: {
		type: String,
		required: true
	}
})
const emits = defineEmits(["updateQueue", "qsAllQueue"])
const api = useAPI()
const { user } = useAuth0()
const pendoStore = usePendoStore();
const storeProfile = useProfile()
const storeTaxroll = useTaxroll()
const storeAdminDetails = useAdminDetails()
const storeCandidateDetails = useCandidateDetailsStore()
const formatter = getCurrencyFormatter()
const recordLabel = computed(() => totalSelectedCandidate.value > 1 ? "records" : "record")
const userId = computed(() => {
	return user.value?.sub || null
})
let snoozeDays = 0;

const strapCardHeaderActionsRef = ref<typeof StrapCardHeaderActions | null>(null)
const loading = ref(true)
watch(() => storeProfile.loadingState, (loadingState: boolean) => {
	loading.value = loadingState
})

const op = ref()
const selectedUserInfo = ref("")
const displayUserInfo = (event: any, userId: string) => {
	op.value.hide()
	selectedUserInfo.value = userId
	nextTick(() => {
		op.value.show(event)
	})
}

const loadingFilterSchema = ref(true)
const filterSchema = ref<FilterSchema[]>([])
const filterConfig = ref<FilterConfig | null>(null)
const first = ref(0)
const rows = ref(25)
const totalRecords = ref(0)
const data = ref<CandidateList[]>([])
type BulkActionsAvailable = "UPDATE_QUEUE" | "ASSIGN" | "UNASSIGN" | "ASSIGN_TO_ME" | "UPDATE_LABEL" | null
const selectedBulkAction = ref<BulkActionsAvailable>(null)
const userFilters = computed(() => {
	return filters.value.filter(obj => obj.field && obj.type)
})

const listDrawerItemID = ref("")
const listDrawerVisible = ref(false)

const dataParams = ref<DataTableEvent | null>(null)
const dataFiltersInitialState = {
	tru_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
	parcel_num: { value: null, matchMode: FilterMatchMode.CONTAINS },
	applicant: { value: null, matchMode: FilterMatchMode.CONTAINS },
	flags: { value: null, matchMode: FilterMatchMode.IN },
	tax_year: { value: null, matchMode: FilterMatchMode.IN },
	exemptions: { value: null, matchMode: FilterMatchMode.IN },
	submitted_date: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
	application_status_recommendation: { value: null, matchMode: FilterMatchMode.IN },
	application_status: { value: null, matchMode: FilterMatchMode.EQUALS },
	assigned_user_id: { value: null, matchMode: FilterMatchMode.EQUALS },
}
const dataFilters = ref({ ...dataFiltersInitialState })

const selectedItems = ref<string[]>([])
const isRecordsInCurrentPageSelected = ref(false)
const isAllRecordsMatchingFiltersSelected = ref(false)
const toggledSelectAll = computed((): boolean => (data.value.length > 0 && data.value.every(item => selectedItems.value.includes(item.tru_id))))
watch(toggledSelectAll, (newValue) => isRecordsInCurrentPageSelected.value = newValue)
const toggleSelectAllMatchingFilters = () => {
	isAllRecordsMatchingFiltersSelected.value = true
	toggleCheckboxes(".p-dataview-content .p-checkbox input", true, true)
}

const totalSelectedCandidate = computed(() => {
	if (isAllRecordsMatchingFiltersSelected.value) {
		return totalRecords.value
	}
	if (isRecordsInCurrentPageSelected.value) {
		return data.value.length
	}
	else {
		return selectedItems.value.length
	}
})


/*** Quick Search ***/
const quickSearchQueryTitle = "Search by owner, parcel ID or address"
const addToQuickSearch = (parcelNum: string) => {
	if (quickSearchQuery.value) quickSearchQuery.value += `, ${parcelNum}`
	else quickSearchQuery.value = parcelNum
}
const quickSearchQuery = ref<string>(storeTaxroll.getQuickSearchQuery || "")
const showQSMessage = computed(() => !loading.value && filteringWithQS.value && quickSearchQuery.value)
const filteringWithQS = ref<boolean>(false)
const enabledQuickSearchAllQueue = ref<boolean>(false)
const qsIncludeArchive = ref<boolean>(false)
const quickSearchAllQueue = async (includeArchive: boolean = false) => {
	qsIncludeArchive.value = includeArchive
	storeTaxroll.setQuickSearchQuery(quickSearchQuery.value)
	emits("qsAllQueue")
	await initializeApp()
}

const getDefaultFilters = (): {
    field: string;
    type: string;
    value: string | string[];
}[] => {
	if (props.queue === QueueMap.All.id || props.queue === QueueMap.AssignedToMe.id) {
		return [{ field: "queue", type: "not in", value: QueueMap.Archive.id }]
	}
	else {
		return [{ field: "queue", type: "=", value: props.queue }]
	}
}
const defaultFilters = ref(getDefaultFilters())
let removedDefaultFilter: FilterFields
const initialFilters = [
	{
		field: "",
		type: "",
		value: ""
	},
]

const filters = ref<FilterFields[]>(initialFilters)
const filterSelectedItems = ref<FilterFields[] | null>(null)
const currentPage = ref(1)
const isFilteringDirty = ref(false)
const hasPermissionToReadCandidates = computed(() => validateUserPermission("read", "candidates"))
const hasPermissionForUnreleasedInvestigationQueue = computed(() => validateUserPermission("read", "unreleased_investigation_reports"))
const promonListLayoutQueues = ['inbox', 'monitor', 'assigned_to_me']

const hasPermissionToViewUnreleasedIR = computed(() => (storeProfile.canViewUnreleasedIRList))
const hasPermissionToViewReleasedIR = computed(() => (storeProfile.canViewReleasedIRList))
const hasPermissionToViewInvestigateNow = computed(() => (storeProfile.canViewInvestigateNowList))

const onBulkNoteClick = () => {
	if (checkFiltersDirty())
		return
	bulkNote.showNoteDialog.value = true
}

const onBulkLabelClick = () => {
	if (checkFiltersDirty())
		return
	showLabelDialog.value = true
}

const onBulkActionClick = (operation: BulkActionsAvailable) => {
	if (checkFiltersDirty())
		return
	hasArchiveItems()
	selectedBulkAction.value = operation
}

const onBulkIRClick = (action: "delete") => {
	if (checkFiltersDirty())
		return
	if (action === "delete") {
		bulkIR.showDeleteIRDialog.value = true
	}
}

const onFiltersDirtyChanged = (value: boolean) => {
	isFilteringDirty.value = value
}

const checkFiltersDirty = () => {
	if (isFilteringDirty.value) {
		toast.warning("Your filter changes have not been applied.<br>Please review your choices, and press 'Go'.", { "position": "top" })
		return true
	}
	return false
}

const handleRemoveFilters = (emitFilter: any) => {
	filters.value = emitFilter.filters
	if (emitFilter.removedDefaultFilter) {
		removedDefaultFilter = emitFilter.removedDefaultFilter
	}

	if (emitFilter.removeAll) {
		defaultFilters.value = []
	}
	isRecordsInCurrentPageSelected.value = false
	setFilterConfig()
	loadData(null)
}

// Resets the current page to 1 (UI) whenever the list is filtered
const paginationResetCounter = ref(0)

const handleSubmitFilters = (emitFilter: any) => {
	selectedItems.value = [] // clear all selected items
	filters.value = emitFilter.filters
	qsIncludeArchive.value = false
	if (!emitFilter.includeDefaults) {
		defaultFilters.value = []
	}
	currentPage.value = 1
	paginationResetCounter.value += 1
	// When use clicks the "Clear All", reset selection
	isRecordsInCurrentPageSelected.value = false
	loadData(null, emitFilter.qs)
}

const handleActivateListDrawerClick = (id: string) => {
	listDrawerItemID.value = id
	listDrawerVisible.value = true
}

const operatorSubstitutions: OperatorSubstitution[] = [
	{ type: "date", operator: ">=", text: "Is After" },
	{ type: "date", operator: "<", text: "Is Before" },
	{ field: "parcel_value", operator: ">=", text: "Is Greater Than" },  //per product, omit the "or equal to" part
	{ field: "parcel_value", operator: "<=", text: "Is Less Than" },  //per product, omit the "or equal to" part
]

const operatorExclusions: OperatorExclusion[] = [
	{ field: "queue", operator: "is null" },
	{ field: "prev_queue", operator: "is null" },
]

const onSelectAllChange = (checked: boolean = true) => {
	isRecordsInCurrentPageSelected.value = checked
	if (isAllRecordsMatchingFiltersSelected.value) {
		isAllRecordsMatchingFiltersSelected.value = checked
	}
	handleSelectAllChange()
}

const handleSelectAllChange = (add = true) => {
	const currentPageIds = data.value.map(data => data.tru_id)

	if (add) {
		// Add only new unique IDs
		const newItems = currentPageIds.filter(tru_id => !selectedItems.value.includes(tru_id))
		selectedItems.value.push(...newItems)
	} else {
		// Remove all matching IDs from selectedItems
		selectedItems.value = selectedItems.value.filter(tru_id => !currentPageIds.includes(tru_id))
	}
}

const queueTabs = ref<string[]>(promonQueueTabs.filter((q) => q.id !== 'monitor').map((q: any) => (q.id)) as [])
queueTabs.value.push("unreleased_ir")
const checkQueueParam = () => {
	if (!queueTabs.value.includes(props.queue)) {
		window.location.href = DEFAULT_HOMEPAGE
	}
}

// Add to To-Do list
const truIDsToMoveToTodoList = ref<Record<string, NodeJS.Timeout>>({})
const visibleUndoButtons = ref<Record<string, boolean>>({})

const addToTodoList = (tru_id: string) => {
	// If the tru_id already has a timeout, clear it before setting a new one
	if (truIDsToMoveToTodoList.value[tru_id]) {
		clearTimeout(truIDsToMoveToTodoList.value[tru_id])
	}

	const strapItem = document.querySelector(`.strap-${tru_id}`) as HTMLDivElement

	// Show Undo button
	visibleUndoButtons.value[tru_id] = true

	// Set a timeout of MOVE_TO_TODO_LIST_TIMER seconds for this specific tru_id
	truIDsToMoveToTodoList.value[tru_id] = setTimeout(async () => {
		try {
			await api.patch(`${bulkQueue.parentURL}/queue`, {
				tru_ids: [tru_id],
				note: "",
				queue: "todo"
			})

			emits("updateQueue", {
				prevQueue: props.queue,
				queue: QueueMap.Todo.id,
			})

			toast.success("Added to the To-Do list")

			// Pinned! 
			data.value.filter((d) => d.tru_id === tru_id).map((d) => {
				d.queue = QueueMap.Todo.id
			})

			// Disable the strap item
			if (strapItem) {
				strapItem.classList.add("strap-disabled")
			}
		}
		catch (error: any) {
			toast.error(getApiErrorMessage(error))
		}
		finally {
			// Clean up the timeout reference
			delete truIDsToMoveToTodoList.value[tru_id]
			visibleUndoButtons.value[tru_id] = false // Hide undo button after API call
		}

	}, storeTaxroll.MOVE_TO_TODO_LIST_TIMER)
}

const undoAddToTodoList = (tru_id: string) => {
	if (truIDsToMoveToTodoList.value[tru_id]) {
		clearTimeout(truIDsToMoveToTodoList.value[tru_id])
		delete truIDsToMoveToTodoList.value[tru_id]
		visibleUndoButtons.value[tru_id] = false
		toast.info("Action undone")
	}
}

/*** Bulk Queue ***/
const itemArchiveLabel = QueueMap.Archive.text + " as sold"
const queueOptions = [
	{ label: ucwords(QueueMap.Todo.text), value: QueueMap.Todo.id },
	{ label: ucwords(QueueMap.Inbox.text), value: QueueMap.Inbox.id },
	{ label: ucwords(QueueMap.Questionnaire.text), value: QueueMap.Questionnaire.id },
	{ label: ucwords(QueueMap.Monitor.text), value: QueueMap.Monitor.id },
	{ label: ucwords(QueueMap.Unqualified.text), value: QueueMap.Unqualified.id },
	{ label: ucwords(QueueMap.Snooze.text), value: QueueMap.Snooze.id },
	{ label: ucwords(itemArchiveLabel), value: QueueMap.Archive.id },
	{ label: ucwords(QueueMap.InvestigateNow.text), value: QueueMap.InvestigateNow.id, hidden: true },
	{ label: ucwords(QueueMap.InvestigationReports.text), value: QueueMap.InvestigationReports.id, hidden: true },
	{ label: ucwords(QueueMap.UnderInvestigation.text), value: QueueMap.UnderInvestigation.id, hidden: true }
]
const filteredQueueOptions = computed(() => queueOptions.filter((q: any) => !q.hidden))

const updateQueueOptionVisibility = (optionValue: string, show: boolean) => {
	queueOptions.forEach((option) => {
		if (option.value === optionValue) {
			option.hidden = !show;
		}
	});
};


watch(() => hasPermissionToViewUnreleasedIR.value, (show: boolean) =>
	updateQueueOptionVisibility('unreleased_ir', show)
)

watch(() => hasPermissionToViewReleasedIR.value, (show: boolean) =>
	updateQueueOptionVisibility('released_ir', show)
)

watch(() => hasPermissionToViewInvestigateNow.value, (show: boolean) =>
	updateQueueOptionVisibility('investigate_now', show)
)

const queuesWithReasons = ["monitor", "unqualified", "snooze"]
const selectedQueue = ref<QueueID | null>(null)
watch(() => selectedQueue.value, async (queue: any) => {
	if (!queuesWithReasons.includes(queue) || !queue) return
	await storeCandidateDetails.fetchReasonsList(loadFailureHandler, queue)
})
const mailingTemplateDropdown = computed(() => storeCandidateDetails.getMailTemplateList)
const reasonsDropdown = computed(() => storeCandidateDetails.getReasonsList)
const bulkQueue = {
	loading: ref(false),
	showQueueDialog: computed(() => selectedBulkAction.value === "UPDATE_QUEUE"),
	optionalNote: ref(""),
	prevQueue: ref<string>(props.queue),
	queue: computed(() => selectedQueue.value),
	mailingTemplate: ref<number | null>(null),
	snoozeUntil: ref<string>(""),
	reason: ref<string>(""),
	unqualifiedStartYear: ref<string | null>(null),
	lienOrBackTaxes: ref<boolean>(false),
	backTaxLienAmount: ref<number>(0),
	saleDate: ref<Date | null>(null),
	finalOutcome: ref<string>(""),
	recoveredAmount: ref<number | null>(null),
	modalTitle: computed((): string => {
		let title = `Update ${formatNumberWithCommas(totalSelectedCandidate.value)} ${recordLabel.value} to`
		// Custom title for Archive
		const queueSelected = bulkQueue.queue.value === "archive" ? itemArchiveLabel : getQueueTextByID(bulkQueue.queue.value as string)
		title += (bulkQueue.queue.value) ? ` ${queueSelected}` : "..."
		return title
	}),
	parentURL: "/taxroll"
}
const bulkQueueUpdateButtonDisabled = computed(() => {
	const {
		queue,
		mailingTemplate,
		reason,
		snoozeUntil,
		unqualifiedStartYear,
		optionalNote
	} = bulkQueue

	if (!queue.value) return true

	const requireOptionalNote = reason.value.toLowerCase() === 'other'

	const hasRequiredFieldsForUnqualified = () => {
		return unqualifiedStartYear.value && reason.value
	}

	const unqualifiedConditions = (): boolean => {
		return !hasRequiredFieldsForUnqualified()
	}

	const conditions: Partial<Record<QueueID | QueueText, boolean>> = {
		questionnaire: !mailingTemplate.value,
		monitor: (requireOptionalNote) ? !reason.value || !optionalNote.value : !reason.value,
		snooze: (requireOptionalNote) ? !snoozeUntil.value || !reason.value || !optionalNote.value : !snoozeUntil.value || !reason.value,
		unqualified: unqualifiedConditions(),
	}
	return conditions[queue.value] ?? false
})

const useGenericOptionsForQueueChange = (queue: string | null) => {
	if (!queue)
		return false
	const possibleQueues = [
		QueueMap.Todo.id.toString(),
		QueueMap.Inbox.id.toString(),
		QueueMap.InvestigateNow.id.toString(),
		QueueMap.InvestigationReports.id.toString(),
		QueueMap.UnderInvestigation.id.toString()
	]
	return possibleQueues.includes(queue)
}

const fetchMailingTemplateList = async () => {
	await storeCandidateDetails.fetchMailTemplateList(loadFailureHandler)
}
const submitBulkQueue = async () => {
	const additionalParam = (isAllRecordsMatchingFiltersSelected.value ? {
		filters: getFilterList()
	} : {
		tru_ids: selectedItems.value
	})
	const result = await performQueueAction(api, bulkQueue, additionalParam)
	if (result === "success") {
		if (props.queue !== "all") clearAllSelectedItems(false)
		loadData(null, filteringWithQS.value)
		emits("updateQueue", {
			prevQueue: bulkQueue.prevQueue.value,
			queue: bulkQueue.queue.value,
		})
		resetToInitialState()
		closeQueueDialog()
	}
	bulkQueue.loading.value = false
}
const closeQueueDialog = () => {
	selectedBulkAction.value = null
	selectedQueue.value = null
	bulkQueue.optionalNote.value = ""
	bulkQueue.snoozeUntil.value = ""
	bulkQueue.reason.value = ""
	bulkQueue.mailingTemplate.value = null
	bulkQueue.unqualifiedStartYear.value = null
	bulkQueue.lienOrBackTaxes.value = false
	bulkQueue.backTaxLienAmount.value = 0
	bulkQueue.finalOutcome.value = ""
	bulkQueue.saleDate.value = null
	bulkQueue.recoveredAmount.value = null
	showArchiveWarningModal.value = false
}

// We will no longer be using the field parcel_updated, use updated_at instead
// Code refactored.
const updatedOn = (candidate: CandidateList): string | null => {
	const updatedAt = candidate.updated_at ? dayjs(candidate.updated_at) : null;
	if (!updatedAt || !updatedAt.isValid()) return null;
	// special case handling: if date is 12/31/9999 then assume 
	// we've received "infinity" converted to 12/31/9999 from server
	if (updatedAt.isAfter(Date.parse("9999-01-01"), "day"))
		return null
	return formatDate(updatedAt.toISOString());
}

const lastQueueChange = (date: string, queue: QueueID) => {
	const dayjsDate = dayjs(date)
	if (!dayjsDate.isValid()) return null
	// e.g. 2 months in Inbox / A year in Inbox
	return `${ucfirst(dayjsDate.fromNow(true))} in ${ucwords(getQueueTextByID(queue) || "")}`
}

/*** Bulk Assign ***/
const userList = computed(() => storeAdminDetails.getAssignableUsersList)
const fetchAssignableUsersList = async () => await storeAdminDetails.fetchAssignableUsersList(loadFailureHandler)
const assignActionItems = [
	{ label: 'Assign', command: () => onBulkActionClick("ASSIGN") },
	{
		label: 'Assign to me',
		command: () => onBulkActionClick("ASSIGN_TO_ME"),
	},
]
const saveBulkAssign = async (requestMade: boolean) => {
	if (requestMade) {
		toast.success("Your submission was successful")
		loadData(null, filteringWithQS.value)
		resetToInitialState()
		closeAssignDialog()
	}
}
const closeAssignDialog = () => selectedBulkAction.value = null

/*** Bulk Label ***/
const showLabelDialog = ref(false)
const labelList = ref<Label[]>([])
const saveBulkLabel = async (requestMade: boolean) => {
	if (requestMade) {
		toast.success("Your submission was successful")
		loadData(null, filteringWithQS.value)
		resetToInitialState()
		closeLabelDialog()
	}
}
const closeLabelDialog = () => selectedBulkAction.value = null

/*** Bulk Note ***/
const bulkNote = {
	note: ref(""),
	loading: ref(false),
	showNoteDialog: ref(false),
	parentURL: "/taxroll"
}
const submitBulkNote = async () => {
	const additionalParam = (isAllRecordsMatchingFiltersSelected.value ? {
		filters: getFilterList()
	} : {
		tru_ids: selectedItems.value
	})
	const result = await performNoteAction(api, bulkNote, additionalParam)
	if (result === "success") {
		loadData(null, filteringWithQS.value)
		resetToInitialState()
		closeNoteDialog()
	}
	bulkNote.loading.value = false
}
const closeNoteDialog = () => {
	selectedBulkAction.value = null
	bulkNote.note.value = ""
	bulkNote.showNoteDialog.value = false
}

/*** Release IR ***/
const bulkIR = {
	action: ref<BulkIRAction>(null),
	loading: ref(false),
	showDeleteIRDialog: ref(false),
	parentURL: "/taxroll"
}
const submitBulkIR = async (action: BulkIRAction) => {
	const additionalParam = (isAllRecordsMatchingFiltersSelected.value ? {
		filters: getFilterList()
	} : {
		tru_ids: selectedItems.value
	})
	bulkIR.action.value = action
	const result = await performIRAction(api, bulkIR, additionalParam)
	if (result === "success") {
		loadData(null, filteringWithQS.value)
		emits("updateQueue", {
			prevQueue: props.queue,
			queue: "inbox",
		})
		resetToInitialState()
		closeIRDialog()
	}
	bulkIR.loading.value = false
}
const closeIRDialog = () => {
	selectedBulkAction.value = null
	bulkIR.showDeleteIRDialog.value = false
}

// Check if there are any 'archive' items in the selected items
const showArchiveWarningModal = ref(false)
const toggleUpdateQueueAction = (action: "confirm" | "submit" | "close") => {
	if (action === "confirm") {
		if (countSelectedArchiveItems.value > 0) {
			showArchiveWarningModal.value = true
			selectedBulkAction.value = null
		}
		else submitBulkQueue()
		return
	}

	if (action === "submit") {
		submitBulkQueue()
		return
	}

	if (action === "close") {
		selectedBulkAction.value = "UPDATE_QUEUE"
		showArchiveWarningModal.value = false
		return
	}
}
const countSelectedArchiveItems = ref(0)
const hasArchiveItems = async () => {
	const filter = [
		{
			field: "tru_id",
			type: "in",
			value: selectedItems.value
		},
		{ field: "queue", type: "=", value: "archive" }
	]
	try {
		const response = await api.post("/taxroll/count", filter)
		countSelectedArchiveItems.value = response.data.count || 0
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
	}
}

const initFilters = () => {
	dataParams.value = {
		first: 0,
		rows: 25,
		page: 1,
		sortField: null,
		sortOrder: null,
		filters: dataFilters.value
	}
	dataFilters.value = dataFiltersInitialState
}

const clearSelection = () => {
	isAllRecordsMatchingFiltersSelected.value = false
	selectedBulkAction.value = null
	handleSelectAllChange(false)
	toggleCheckboxes(".p-dataview-content .p-checkbox input")
	isRecordsInCurrentPageSelected.value = false
}

const resetToInitialState = () => {
	// Check if the selected bulk action is not "UPDATE_QUEUE"
	if (selectedBulkAction.value !== "UPDATE_QUEUE" && !showArchiveWarningModal.value) {
		// If either filtering is applied or there are user filters
		if (filteringWithQS.value || userFilters.value.length) {

			// If the queue is "all", no need to reset
			if (props.queue === "all") return

			// Reset selected items and clear selection
			selectedItems.value = []
			clearSelection()
			return
		}
	}
	else {
		// If the queue is "all", no need to reset
		if (props.queue === "all") return
		// Reset selected items and clear selection for specific queue
		selectedItems.value = []
		clearSelection()
	}
}

const isShowAllSelectedItems = ref(false)
const showAllSelectedItems = () => {
	isShowAllSelectedItems.value = true
	filterSelectedItems.value = [
		{
			field: "tru_id",
			type: "in",
			value: selectedItems.value
		}
	]
	first.value = 0
	currentPage.value = 1
	paginationResetCounter.value += 1
	loadData(null)
}

const clearAllSelectedItems = (refreshList: boolean = true) => {
	isShowAllSelectedItems.value = false
	selectedItems.value = []
	filterSelectedItems.value = []
	isRecordsInCurrentPageSelected.value = false
	isAllRecordsMatchingFiltersSelected.value = false
	if (refreshList) loadData()
	toggleCheckboxes(".p-dataview-content .p-checkbox input")
}

// For Dynamic ScrollHeight Calculation
const candidateDataView = ref<HTMLElement | null>(null)

// Sort
const sortOrder = ref<"desc" | "asc">("desc")
const defaultSortOrder = (): "desc" | "asc" => {
	const ascQueues = ["questionnaire", "unqualified", "snooze"];
	const defaultSortOrder = ascQueues.includes(props.queue) ? "asc" : "desc";
	return defaultSortOrder
}
const changeSortOrder = () => {
	sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc"
	// Retain the quick search state
	loadData(null, filteringWithQS.value)
}

const getListPayload = (event: any) => {
	return {
		"filter": getFilterList(),
		"page": event?.page,
		"page_size": event?.rows,
		"sort": getSortList(),
		"include_total": false
	}
}

const getFilterList = () => {
	let output: FilterFields[] = []

	// Filtering using quick search
	if (filteringWithQS.value && quickSearchQuery.value) {
		const qsQuery = {
			field: "quick_search",
			type: "=",
			value: quickSearchQuery.value
		}
		output.push(qsQuery)

		// Reset all filter selections made by the user
		filters.value = []
	}

	// Filtering using filter options/section
	else {
		if (isShowAllSelectedItems.value && filterSelectedItems.value?.length) {
			output = filterSelectedItems.value
		}
		else output = [...userFilters.value]

		// Clear quick search query
		quickSearchQuery.value = ""
	}

	// Remove any default filters that the user has requested to be removed.
	if (removedDefaultFilter) {
		defaultFilters.value = defaultFilters.value.filter(o =>
			!(o.field === removedDefaultFilter.field &&
                o.type === removedDefaultFilter.type &&
                o.value === removedDefaultFilter.value)
		)
	}

	// Add default filters if QS is not enabled
	if (!enabledQuickSearchAllQueue.value) {
		if (defaultFilters?.value.length) {
			// Check if the default filter already exists, based on the 
			// exact filter field, type, and value
			defaultFilters.value.forEach(defaultFilter => {
				const exists = output.some(item => item.field === defaultFilter.field
                    && item.type === defaultFilter.type
                    && item.type === defaultFilter.value);
				// If it doesn't exist, push the filter to the output
				if (!exists) {
					output.push(defaultFilter);
				}
			});
		}
	}

	const userAssignedFilter: FilterFields = { field: "assigned_to", type: "in", value: [userId.value] }

	if (userId.value) {
		const isAssignedToPresent = output.some((x: FilterFields) => x.field === "assigned_to")

		const shouldAddUserFilter =
            props.queue === "assigned_to_me" ||
            (!isAssignedToPresent && !hasPermissionToReadCandidates.value && props.queue !== QueueMap.All.id)

		// Always include the assigned_to filter when in "Assigned To Me" tab
		if (shouldAddUserFilter) {
			output.push(userAssignedFilter)
		}
	}

	if (filteringWithQS.value && qsIncludeArchive.value) {
		// Promon
		// QS: When filtering all queues, including archived items, remove the filter that blocks the archive
		output = output.filter((o) => (!(o.field === "queue" && o.type === "not in" && o.value === "archive")))
	}

	return output
}

const sortTitles = ref({
	asc: "Last Queue Change: Oldest First",
	desc: "Last Queue Change: Newest First",
})
const getSortList = () => {
	let fieldName = "last_queue_update";
	let customSortOrder = sortOrder.value
	switch (props.queue) {
		case QueueMap.Inbox.id:
		case QueueMap.AssignedToMe.id:
			fieldName = "priority_score";
			sortTitles.value = {
				asc: "Priority Score: Low to High",
				desc: "Priority Score: High to Low",
			}
			break;
		case QueueMap.Snooze.id:
			fieldName = "snooze_until";
			sortTitles.value = {
				asc: "Snooze Until: Earliest First",
				desc: "Snooze Until: Latest First",
			}
			customSortOrder = defaultSortOrder() === sortOrder.value ? "desc" : "asc"
			break;
	}

	return [{ "field": fieldName, "dir": customSortOrder }]
}

const loadData = async (event: any = null, qs: boolean = false) => {
	loading.value = true
	dataParams.value = { ...dataParams.value, page: event?.page || currentPage.value, rows: event?.rows || 25 }

	filteringWithQS.value = qs
	const listPayload = getListPayload(dataParams.value)
	const requests = []

	const listRequest = api.post("/taxroll/list", listPayload)?.then(response => {
		data.value = response.data?.data || []
	})?.catch(error => {
		data.value = []
		toast.error(getApiErrorMessage(error))
	})
	requests.push(listRequest)

	const countPayload = listPayload.filter
	const countRequest = api.post("/taxroll/count", countPayload)?.then(response => {
		totalRecords.value = response.data.count || 0
	})?.catch(error => {
		toast.error(getApiErrorMessage(error))
	})
	requests.push(countRequest)

	await Promise.all(requests)

	let sidebarContent = document.querySelector("#sharedSideNav .sidebar-content") as HTMLDivElement
	if (sidebarContent) {
		sidebarContent.style.marginBottom = totalRecords.value === 0 ? "60px" : "0px"
	}

	if (isAllRecordsMatchingFiltersSelected.value) {
		handleSelectAllChange()
		toggleCheckboxes(".p-dataview-content .p-checkbox input", true, true)
	}

	loading.value = false
}

const fetchLabelsDropdownList = async () => {
	try {
		const response = await api.get('/labels/?candidates=true');
		const items = response.data ? response.data : []
		labelList.value = items as Label[]
	}
	catch (error: any) {
		labelList.value = [];
		toast.error(getApiErrorMessage(error))
	}
}

const loadFailureHandler = (error: any) => {
	const message = getApiErrorMessage(error, { "featureName": "Report" })
	toast.error(message)
}

const onPage = async (event: DataTableEvent) => {
	if (isFilteringDirty.value) {
		toast.warning("Your current filter settings are unsaved. <br> To see updates, please apply them by pressing 'Apply,' as changes may not be reflected on this page.", { "position": "top", "duration": 5000 })
		return
	}
	event.page += 1
	dataParams.value = event
	currentPage.value = event.page
	rows.value = event.rows as number
	await loadData(event, filteringWithQS.value)
	isRecordsInCurrentPageSelected.value = false
	if (isAllRecordsMatchingFiltersSelected.value) {
		handleSelectAllChange()
		toggleCheckboxes(".p-dataview-content .p-checkbox input", true, true)
	}
	scrollToView("row_0")
}

const setFilterConfig = () => {
	const fieldExclusions = props.queue === QueueMap.All.id || props.queue === QueueMap.AssignedToMe.id ? [] : ["queue"]
	filterConfig.value = {
		activeFilters: filters.value,
		currentFilteredFields: userFilters.value.length,
		showDefaultFilters: (props.queue === "all" || props.queue === "assigned_to_me" ? true : false),
		defaultFilters: defaultFilters.value,
		filterSchema: filterSchema.value,
		operatorSubstitutions: operatorSubstitutions,
		operatorExclusions: operatorExclusions,
		fieldExclusions: fieldExclusions
	}
}

const fetchFilterSchema = async () => {
	loadingFilterSchema.value = true
	try {
		const response = await api.get('/taxroll/filters')
		filterSchema.value = response.data as FilterSchema[]
		//setFilterConfig()
	}
	catch (error: any) {
		filterSchema.value = [];
		toast.error(getApiErrorMessage(error))
	}
	loadingFilterSchema.value = false
}

// Filter overlay (popup)
const overlayStyle = ref({})
const filterButton = ref<HTMLElement | null>(null)

const setFilterDivPosition = () => {
	if (filterButton.value && candidateDataView.value) {
		const filterButtonRect = filterButton.value.getBoundingClientRect()
		const candidateDataViewRect = candidateDataView.value.getBoundingClientRect()
		overlayStyle.value = {
			position: 'absolute',
			top: `${filterButtonRect.bottom + 15}px`,
			right: `${candidateDataViewRect.right - filterButtonRect.right + 10}px`
		}
	}
}

const promonListLayout = ref()
const getDefaultSnoozeDays = async () => snoozeDays = await storeAdminDetails.getDefaultSnoozeDays(InternalSettings.promon_default_snooze_days)
const fetchInternalSettings = async () => {
	getDefaultSnoozeDays()
	if (!promonListLayoutQueues.includes(props.queue)) return
	promonListLayout.value = await storeAdminDetails.fetchInternalSettingsItem(InternalSettings.promon_list_layout, loadFailureHandler)
	if (promonListLayout.value?.value) {
		promonListLayout.value = promonListLayout.value.value[0]?.columns[0]?.fields[0]?.field_name || "priority_score"
	}
	else promonListLayout.value = "priority_score"
}

watch(
	[filters, filterSchema],
	() => { setFilterConfig() },
	{ deep: true }
)

const initializeApp = async () => {
	checkQueueParam()
	setFilterDivPosition()
	initFilters()
	fetchFilterSchema()
	fetchInternalSettings()
	await loadData(null, !!quickSearchQuery.value)

	updateQueueOptionVisibility('investigate_now', hasPermissionToViewInvestigateNow.value)
	updateQueueOptionVisibility('unreleased_ir', hasPermissionForUnreleasedInvestigationQueue.value)
	updateQueueOptionVisibility('released_ir', hasPermissionToViewReleasedIR.value)

	fetchLabelsDropdownList()
	fetchAssignableUsersList()
	fetchMailingTemplateList()
}

onMounted(async () => {
	await initializeApp()
})

onUnmounted(() => {
	quickSearchQuery.value = ""
	enabledQuickSearchAllQueue.value = false
	filteringWithQS.value = false
	qsIncludeArchive.value = false
	storeTaxroll.setQuickSearchQuery("")
})

pendoStore.track()

</script>

<style>
@import "@/assets/appvet.css";

.icon-container {
    position: relative;
    display: inline-block;
}

.tag-icon {
    color: #2E60A6;
    font-size: 2rem;
}

.icon-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    font-size: 0.40rem;
    pointer-events: none;
}
</style>