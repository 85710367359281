import type { ComputedRef, Ref } from "vue"
import type { Label } from "@/helpers/interface/candidates"
import { toast } from "@/helpers/toast"
import { APPLICATION_STATUS_MAP, 
	downloadCSV, 
	formatDate, 
	getApiErrorMessage, 
	QueueMap, 
	type QueueID, 
	type ApplicationStatus 
} from "@/helpers/common"
import type { Auth0User } from "@/helpers/interface/admin-page"
import type { Axios } from "axios"
import dayjs from "dayjs"
import type { CheckboxTriState } from "./interface/general"

interface BulkLabelAction {
    add: Ref<Label[]>;
    remove: Ref<Label[]>;
    optionalNote: Ref<string>;
    loading: Ref<boolean>;
    parentURL: string;
}

interface BulkAssign {
    sub: string | undefined;
    user: Ref<Auth0User | null>;
    optionalNote: Ref<string>;
    loading: Ref<boolean>;
    showAssignDialog?: ComputedRef<boolean>;
    showAssignToMeDialog?: ComputedRef<boolean>;
    showUnassignDialog?: ComputedRef<boolean>;
    parentURL: string;
}

export type BulkIRAction = "delete" | null
export interface BulkIR {
    action: Ref<BulkIRAction>;
    loading: Ref<boolean>;
    showDeleteIRDialog: Ref<boolean>;
    parentURL: string;
}

export interface BulkQueue {
    loading: Ref<boolean>;
    showQueueDialog: Ref<boolean>;
    optionalNote: Ref<string>;
    queue: Ref<QueueID | null>;
    mailingTemplate?: Ref<number | null>;
    snoozeUntil?: Ref<string>;
    reason?: Ref<string>;
    unqualifiedStartYear?: Ref<string | null>;
    lienOrBackTaxes?: Ref<boolean>;
    backTaxLienAmount?: Ref<number | null>;
    finalOutcome?: Ref<string | null>;
    saleDate?: Ref<Date | null>;
    recoveredAmount?: Ref<number | null>;
    parentURL: string;
}

interface BulkUpdate {
    status: Ref<ApplicationStatus | string>;
    snoozeUntil?: Ref<string>;
    optionalNote: Ref<string>;
    loading: Ref<boolean>;
    statusText: ComputedRef<string>;
    parentURL: string;
    outreachCode?: Ref<number | null>;
    effectiveDate?: Ref<string | null>;
    endDate?: Ref<string | null>;
}

interface BulkNote {
    note: Ref<string>;
    loading: Ref<boolean>;
    showNoteDialog: Ref<boolean>;
    parentURL: string;
}

interface BulkExport {
    export: Ref<"csv" | "pdf">;
    loading: Ref<boolean>;
    showExportDialog: Ref<boolean>;
    parentURL: string;
}

export const showSuccessResult = (data: any, targetQueue?: string) => {
	if (!data)
		return;

	const invalidCount = data["invalid"] || 0
	if (invalidCount > 0) {
		const message = `${invalidCount} parcel(s) were not updated. Possible reasons include:`
		const reasons = [
			"The parcel is archived.",
		]
		if (targetQueue === QueueMap.InvestigationReports.id) {
			reasons.push("There is no report associated with the parcel.")
		}
		const fullMessage = `${message}<ul><li>${reasons.join("</li><li>")}</li></ul>`
		// give the user plenty of time to read and comprehend
		toast.warning(fullMessage, { duration: 30000 })
	}

	if (!("tru_ids" in data) || data["tru_ids"].length > 0)
		toast.success("Your submission was successful")

}


export const performLabelUpdate = async (api: Axios, action: "add" | "remove", bulkLabel: BulkLabelAction, additionalParam: any) => {
	const labelIds = bulkLabel[action].value.map(label => label.id)
	if (labelIds.length === 0) {
		toast.error(`Please select a label to ${action}.`)
		return "error"
	}
	bulkLabel.loading.value = true
	const params = {
		label_ids: labelIds,
		note: bulkLabel.optionalNote.value,
		...additionalParam
	}
	try {
		const url = action === "add" ? `${bulkLabel.parentURL}/labels` : `${bulkLabel.parentURL}/labels/delete`
		await api.post(url, params)
		bulkLabel.loading.value = false
		return "success"
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
		return "error"
	}
}

export const performAssignAction = async (api: Axios, action: "assign" | "unassign" | "assigntome", bulkAssign: BulkAssign, additionalParam: any) => {
   
	bulkAssign.loading.value = true
	const params = {
		user_id: action === "assigntome" ? bulkAssign.sub : bulkAssign.user.value?.user_id || "",
		note: bulkAssign.optionalNote.value,
		...additionalParam
	}
	try {
		await api.patch(`${bulkAssign.parentURL}/assign`, params)
		bulkAssign.loading.value = false
		return "success"
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
		return "error"
	}
}

export const performUpdateStatusAction = async (api: Axios, status: ApplicationStatus, bulkUpdate: BulkUpdate, additionalParam: any) => {
	bulkUpdate.loading.value = true

	// Validations
	if (status === APPLICATION_STATUS_MAP.SNOOZE && !bulkUpdate?.snoozeUntil?.value) {
		toast.error("Please enter a snooze until date")
		return "error"
	}

	const params = {
		status: status,
		note: bulkUpdate.optionalNote.value,
		...additionalParam
	}
	if (status === APPLICATION_STATUS_MAP.SNOOZE && bulkUpdate?.snoozeUntil?.value) {
		params.snooze_until = dayjs(bulkUpdate.snoozeUntil.value).format("YYYY-MM-DD")
	}

	if ([APPLICATION_STATUS_MAP.APPROVED, APPLICATION_STATUS_MAP.DENIED].includes(status)) {
		const { effectiveDate, endDate, outreachCode } = bulkUpdate

		if (outreachCode?.value) params.outreach_code = outreachCode.value

		if (status === APPLICATION_STATUS_MAP.APPROVED) {
			if (effectiveDate?.value) params.effective_date = dayjs(effectiveDate.value).format("YYYY-MM-DD")
			if (endDate?.value) params.end_date = dayjs(endDate.value).format("YYYY-MM-DD")
		}
	}

	try {
		await api.post(`${bulkUpdate.parentURL}/status`, params)
		toast.success("Your submission was successful")
		bulkUpdate.loading.value = false
		return "success"
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
		return "error"
	}
}

export const performNoteAction = async (api: Axios, bulkNote: BulkNote, additionalParam: any) => {
	if (!bulkNote.note.value) {
		toast.error("Please enter a note.")
		return "error"
	}
	const params = {
		note: bulkNote.note.value,
		...additionalParam
	}
	bulkNote.loading.value = true
	try {
		await api.post(`${bulkNote.parentURL}/note`, params)
		toast.success("Your submission was successful")
		bulkNote.loading.value = false
		return "success"
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
		return "error"
	}
}

export const performExportAction = async (api: Axios, queue: string[], bulkExport: BulkExport, additionalParam: any) => {
	const defaultSort = [{ field: "submitted_date", dir: "desc" }]
	const params = {
		sort: defaultSort,
		...additionalParam
	}
	bulkExport.loading.value = true
	try {
		if (bulkExport.export.value === "csv") {
			const response = await api.post(`${bulkExport.parentURL}/export`, params)
			let fileName
			if (queue.includes('pending')) fileName = 'inbox'
			else if (queue.includes('deferred')) fileName = 'deferred'
			else if (queue.includes('approved') || queue.includes('denied')) fileName = 'archive'
			fileName = `${fileName}-applications-`
			fileName = fileName + (new Date()).toISOString().substring(0, 19)
			downloadCSV(response.data, fileName)
			toast.success("Your submission was successful")
		}

		if (bulkExport.export.value === "pdf") {
			const response = await api.post(`${bulkExport.parentURL}/application_zip/export`, params)
			toast.success(response.data.message)
		}

		bulkExport.loading.value = false
		return "success"
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
		return "error"
	}
}


export const performIRAction = async (api: Axios, bulkIR: BulkIR, additionalParam: any) => {
	const params = { ...additionalParam }
	bulkIR.loading.value = true
	try {
		await api.patch(`${bulkIR.parentURL}/investigation_reports/delete`, params)
		toast.success("Your submission was successful")
		bulkIR.loading.value = false
		return "success"
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
		return "error"
	}
}

export const populateParams = (bulkQueue: BulkQueue, queue: string, additionalParam: any) => {
	const params: any = {
		queue,
		note: bulkQueue.optionalNote.value,
		...additionalParam
	}

	if (queue === "questionnaire") {
		params.mailing_template = bulkQueue.mailingTemplate?.value
	}
	else if (queue === "monitor" || queue === "snooze") {
		params.reason = bulkQueue.reason?.value
		if (queue === "snooze") {
			params.snooze_until = dayjs(bulkQueue.snoozeUntil?.value as string).toISOString()
		}
	}
	else if (queue === "unqualified") {
		params.reason = bulkQueue.reason?.value
		params.mailing_template = bulkQueue.mailingTemplate?.value
		params.unqualified_start_year = bulkQueue.unqualifiedStartYear?.value
		if (bulkQueue.lienOrBackTaxes?.value) params.lien_or_back_taxes = bulkQueue.lienOrBackTaxes?.value
		if (bulkQueue.lienOrBackTaxes?.value) params.lien_amount = bulkQueue.backTaxLienAmount?.value
		if (bulkQueue.recoveredAmount?.value) params.recovered_amount = bulkQueue.recoveredAmount?.value
	}
	else if (queue === "archive") {
		params.final_outcome = "Property Sold"
		const saleDate = bulkQueue.saleDate?.value || null
		if (saleDate) {
			const soldPrefix = `Sold ${formatDate(saleDate.toString())}`;
			params.note = (!params.note) ? soldPrefix : `${soldPrefix} 
            ${params.note}`;
		}
	}

	return params
}


export const performQueueAction = async (api: Axios, bulkQueue: BulkQueue, additionalParam: any) => {
	bulkQueue.loading.value = true
	const queue = bulkQueue.queue?.value as QueueID
	const params = populateParams(bulkQueue, queue, additionalParam)

	try {
		const response = await api.patch(`${bulkQueue.parentURL}/queue`, params)
		showSuccessResult(response?.data, bulkQueue.queue?.value as string)
		bulkQueue.loading.value = false
		return "success"
	}
	catch (error: any) {
		toast.error(getApiErrorMessage(error))
		return "error"
	}
}

// Initialize and set the checkbox state based on selection counts
export const setInitialCheckboxStates = (
	itemList: any[],
	getItemSelected: any[],
	selectedItems: string[],
	selectedIds: (number | string)[],
	initialState: Record<string, CheckboxTriState>
) => {
	itemList.forEach(item => {
		// Get the selected item state 
		const count = getItemSelected.find(selected => selected.id === item.id)?.count || 0
		let initial: CheckboxTriState = "unchecked"

		if (count) {
			// Determine initial checkbox state based on selection count
			if (count === selectedItems.length) {
				initial = "checked"
				selectedIds.push(item.id)
			} else if (count <= selectedItems.length && count > 0) {
				initial = "indeterminate"
			} else {
				initial = "unchecked"
			}
		}

		// Store the initial state for comparison
		initialState[item.id] = initial
	})
}