<template>
	<ModalDialog v-if="assign.showAssignDialog.value" :title="assign.dialogTItle.value" :close="closeAssignDialog">
		<Message severity="warn" :closable="false" class="mb-3" v-if="isRecordsInCurrentPageSelected">
			<span v-if="!isAllRecordsMatchingFiltersSelected">
				Changes will be applied to all records on this page.
			</span>
			<span v-else>
				Changes will be applied to all records in this view.
			</span>
		</Message>
		<template v-if="!isAssignedUserCountLoading">
			<div class="element-container always-show-scrollbar" id="bulk-assign-user-container">
				<label for="assign-user" class="form-label">Assign User</label>
				<div class="assign-user-list ms-2">
					<div class="d-block mb-2" v-for="user in userList" :key="user.user_id">
						<Checkbox :value="user.user_id" v-model="selectedAssignedUsers"
							:input-id="`cb-assign-user-${user.user_id}`"
							:indeterminate="initialState[user.user_id] === 'indeterminate'"
							@change="toggleSelectionChange(initialState, user.user_id, $event)" class="me-2" />
						<label class="me-2" :for="`cb-assign-user-${user.user_id}`">
							{{ user.name }}
						</label>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="assign-user-list ms-2">
				<div v-for="n in 6" :key="n">
					<div class="d-inline-flex mb-2">
						<Skeleton width="30px" height="1.5rem" class="me-2"></Skeleton>
						<Skeleton width="250px" height="1.5rem"></Skeleton>
					</div>
				</div>
			</div>
		</template>
		<Textarea v-model="assign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
		<template #footer>
			<Button @click="saveBulkAssign()" id="bulk-assign" :disabled="disableSaveButton">
				<span class="fa fa-save" aria-hidden="true" v-if="!assign.loading.value"></span>
				<span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="assign.loading.value"></span>
				Save
			</Button>
			<Button @click="closeAssignDialog()" severity="secondary">
				<span class="fa fa-times-circle" aria-hidden="true"></span> Close
			</Button>
		</template>
	</ModalDialog>

	<ModalDialog v-if="assign.showAssignToMeDialog.value" :title="`${assign.dialogTItle.value}`"
		:close="closeAssignDialog">
		<Textarea v-model="assign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
		<template #footer>
			<Button @click="saveBulkAssign(true)" id="bulk-assign-to-me"
				:disabled="isAssignedUserCountLoading || assign.loading.value">
				<span class="fa fa-plus-circle" aria-hidden="true" v-if="!assign.loading.value"></span>
				<span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="assign.loading.value"></span>
				Assign To Me
			</Button>
			<Button @click="closeAssignDialog()" severity="secondary">
				<span class="fa fa-times-circle" aria-hidden="true"></span> Close
			</Button>
		</template>
	</ModalDialog>

</template>

<script setup lang="ts">
import type { Auth0User } from '@/helpers/interface/admin-page'
import type { CheckboxTriState } from '@/helpers/interface/general'
import Skeleton from 'primevue/skeleton'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import { formatNumberWithCommas, MAX_NOTE_LENGTH } from "@/helpers/common"
import { computed, ref, watch } from 'vue'
import { useTaxroll } from '@/stores/taxroll'
import { useAuth0 } from '@auth0/auth0-vue'
import { performAssignAction, setInitialCheckboxStates } from '@/helpers/bulkActions'
import { useAPI } from '@/helpers/services/api'
import ModalDialog from '@/components/Shared/ModalDialog.vue'
import Message from 'primevue/message'

const {
	loadFailureHandler,
	getFilterList,
	isRecordsInCurrentPageSelected,
	parentURL
} = defineProps<{
	loadFailureHandler: (error: any) => void;
	getFilterList?: () => void;
	isRecordsInCurrentPageSelected?: boolean;
	parentURL: string;
}>()

const storeTaxroll = useTaxroll()
const { user } = useAuth0()
const api = useAPI()
const emits = defineEmits(["save", "close"])

const action = defineModel<any>("action", { required: true })
const selectedItems = defineModel<string | string[]>("selectedItems", { required: true })
const userList = defineModel<(Auth0User & { id?: string })[]>("userList", { required: true })
const totalSelectedRecord = defineModel<any>("totalSelectedRecord", { default: 0 })
const isAllRecordsMatchingFiltersSelected = defineModel<boolean>("isAllRecordsMatchingFiltersSelected", { required: false, default: false })

const getCountAssignedUser = computed(() => storeTaxroll.getCountAssignedUser)
const selectedAssignedUsers = ref<string[]>([])
const isAssignedUserCountLoading = computed(() => storeTaxroll.countAssignedUserLoading)
const recordLabel = ref("record")
const madeChanges = ref(false)

const disableSaveButton = computed((): boolean => (!madeChanges.value || isAssignedUserCountLoading.value || assign.loading.value))
const currentAssignedUser = ref<string | null>(null)
const isAllUnchecked = ref(false)

// Stores the initial states of checkboxes (used for comparison)
const initialState = ref<Record<string, CheckboxTriState>>({})
const assign = {
	sub: user.value?.sub,
	user: ref<(Auth0User & { id?: string }) | null>(null),
	optionalNote: ref(""),
	loading: ref(false),
	showAssignDialog: computed(() => action.value === "ASSIGN"),
	showAssignToMeDialog: computed(() => action.value === "ASSIGN_TO_ME"),
	showUnassignDialog: computed(() => action.value === "UNASSIGN"),
	parentURL: parentURL,
	dialogTItle: computed((): string => {
		if (isAssignedUserCountLoading.value) return "Loading..."

		const hasIndeterminate = Object.values(initialState.value).includes("indeterminate")
		const totalRecords = formatNumberWithCommas(totalSelectedRecord.value)
		const recordText = computed(() => ((totalSelectedRecord.value > 1) ? `${totalRecords} ${recordLabel.value}` : ` this record `))

		if (getCountAssignedUser.value.length > 0 && selectedAssignedUsers.value.length <= 0 && !hasIndeterminate) {
			return `Clear assignment from ${recordText.value}`
		}

		let action = getCountAssignedUser.value.length ? "Reassign" : "Assign"
		let dialogText = `${action} ${recordText.value}`

		if (selectedAssignedUsers.value.length && assign.showAssignDialog.value) {
			const user = userList.value.find(user => selectedAssignedUsers.value.includes(user.user_id))?.name
			if (user) dialogText += ` to ${user}`
		}

		if (assign.showAssignToMeDialog.value) {
			dialogText += ` to me`
		}

		return dialogText
	})
}

const toggleUnchecked = (initialState: Record<string, CheckboxTriState>, itemID?: string | number) => {
	Object.keys(initialState).forEach(key => {
		if (itemID && itemID === key) {
			initialState[key] = "checked"
		}
		else {
			initialState[key] = "unchecked"
		}
	})
}

const toggleSelectionChange = (
	initialState: Record<string, CheckboxTriState>,
	itemId: number | string,
	event: Event
) => {
	const checkbox = (event.target as HTMLInputElement).checked
	selectedAssignedUsers.value = checkbox ? [itemId as string] : []
	madeChanges.value = true

	// Track Changes
	const assignedCount = selectedAssignedUsers.value.length;

	if (
		(assignedCount > 0 && currentAssignedUser.value === selectedAssignedUsers.value[0]) ||
		(assignedCount === 0 && isAllUnchecked.value)
	) {
		madeChanges.value = false;
	}

	toggleUnchecked(initialState, itemId)
}

const saveBulkAssign = async (assignToMe: boolean = false) => {
	const additionalParam = (isAllRecordsMatchingFiltersSelected.value && getFilterList ? {
		filters: getFilterList()
	} : {
		tru_ids: selectedItems.value
	})
	let requestMade = false

	if (assignToMe) {
		const response = await performAssignAction(api, "assigntome", assign, additionalParam)
		requestMade = response === "success"
	}
	else {
		assign.user.value = userList.value.filter(user =>
			selectedAssignedUsers.value.includes(user.user_id)
		)?.[0] || null
		const response = await performAssignAction(api, "assign", assign, additionalParam)
		requestMade = response === "success"
	}

	if (requestMade) closeAssignDialog()

	emits("save", { requestMade })
	assign.loading.value = false
}

const closeAssignDialog = () => {
	assign.user.value = null
	assign.optionalNote.value = ""

	selectedAssignedUsers.value = []
	initialState.value = {}
	madeChanges.value = false
	emits("close")
}

const initializeState = async () => {
	const truIDs = (Array.isArray(selectedItems.value) ? selectedItems.value : [selectedItems.value[0]]) as string[];
	await storeTaxroll.getAssignedUserSelected(truIDs, loadFailureHandler)
	userList.value.map(user => user.id = user.user_id)
	getCountAssignedUser.value.map(user => user.id = user.assigned_user_id)
	setInitialCheckboxStates(userList.value, getCountAssignedUser.value, truIDs, selectedAssignedUsers.value, initialState.value)
	recordLabel.value = truIDs.length > 1 ? "records" : "record"

	isAllUnchecked.value = !(Object.values(initialState.value).some(value => value === "indeterminate" || value === "checked"))
	currentAssignedUser.value = selectedAssignedUsers.value.length === 1 ? selectedAssignedUsers.value[0] : null
}

watch(() => assign.showAssignDialog.value, async (showAssignDialog: boolean) => {
	if (!showAssignDialog) return
	initializeState()
})

watch(() => assign.showAssignToMeDialog.value, async (showAssignToMeDialog: boolean) => {
	if (!showAssignToMeDialog) return
	initializeState()

})
</script>